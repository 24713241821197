import { getCookie, setCookie } from 'JIX/utils/cookies.js';

function testCookies() {
    setCookie('test', 'none', { path: '/' });
    // if getCookie succeeds, cookies are enabled, since
    //the cookie was successfully created.
    if (!getCookie('test')) { return false; }

    // This deletes the cookie when called
    document.cookie = "test=;path=/;expires=Thu, 01-Jan-1970 00:00:01 GMT";
    return true;
}

document.querySelector('.jix_cookie_error').hidden = testCookies();
